<template>
	<div style="min-height: 100vh; background-color: #03122a">
		<language_en></language_en>
	</div>
</template>

<script>
import language_en from "@/components/pc/languageEnPage.vue";
import router from "../../router";
export default {
	components: {
		language_en,
	},
	name: "",
	methods: {},
	data() {
		return {};
	},
};
</script>

<style scoped></style>
